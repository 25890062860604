import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, CircularImage } from "../globals/style";

const Wrapper = styled.div`
  background:#efefef;
  color: ${(props) => (props.inverse ? "#efefef" : "#1e1d22")};
  min-height: calc(60vh - 80px);
  width: 100%;
  padding-top:5em;
`;

const WrapperFiller = styled.div`
  z-index:0;
  padding-bottom:5em;
  display:block;
  background: linear-gradient(
    180deg
    , #efefef 0%, #efefef 15%,#1e1d22 15%,#1e1d22);
  min-height: 50vh;
  width: 100%;
  @media screen and (max-width: 991px) {
    min-height: ${(props) => (props.sizing ? props.sizing * 10 + 50 : 50)}vh;
  }
`;
const Card = styled.div`
  display:flex;
  flex-direction:column;
  width: 100%;
  max-width: 90vw;
  background: white;
  border-radius: 4px 4px 0 0;
  box-shadow: -6px -2px 7.5px rgba(0, 0, 0, 0.05),
    6px -2px 7.5px rgba(0, 0, 0, 0.05);
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 2;
  min-height: 30vh;
  padding: 3em 1.5em 0 1.5em;
  @media screen and (max-width: 370px) {
    max-width: 100vw;
  }
`;
const ContentHolder = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1440px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
`;
const Lawyer = styled.div`
  flex: 1;
  width: 80%;
  min-width: 500px;
  padding: 1em 2.5em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: 991px) {
    padding: 5%;
    overflow-x: auto;
    min-width: 65vw;
  }
`;
const Profile = styled(CircularImage)`
  min-width: 150px;
  height: auto;
  @media screen and (max-width: 991px) {
    min-width: 100px;
  }
`;
const Name = styled.h3`
  color: #000;
  opacity: 0.87;
  font-size: 1.7em;
  margin-bottom: 0.2em;
  @media screen and (max-width: 991px) {
    font-size: 1em;
  }
`;
const Detail = styled.h4`
  color: #000;
  opacity: 0.67;
  font-size: 1.2em;
  letter-spacing: 1.4px;
  line-height: 1.6;
  margin: 0;
  @media screen and (max-width: 991px) {
    font-size: 0.75em;
  }
`;
const Col = styled.div`
  flex: ${(props) => (props.flex ? props.flex : "1")};
  display: flex;
  flex-direction: column;
  max-width: initial;
`;
const ReadMore = styled(Link)`
  float: right;
  font-weight: 700;
  font-size: 1.3em;
  margin: 0.5em 0.5em 1em 0.5em;
  text-decoration: none;
  color: #3f6564;
  padding: 0.5em 1em;
  border-bottom: 5px solid #3f6564;
  margin-left:auto;
`;
const CardContentField = ({ data }) => (
  <Wrapper>
    <WrapperFiller sizing={data.length}>
      <Card>
        <Title style={{ textAlign: "center" }}>Våre advokater</Title>
        <ContentHolder>
          {data.map(({ node }) => (
            <Lawyer key={node.Name}>
              <Profile
                fluid={
                  node.ProfilePicture &&
                  node.ProfilePicture.childImageSharp && 
		  node.ProfilePicture.childImageSharp.fluid
                }
              />
              <Col flex={3} style={{ marginLeft: "2em" }}>
                <Name>{node.Name}</Name>
                <Detail>{node.Email}</Detail>
                <Detail>{node.Phone}</Detail>
              </Col>
            </Lawyer>
          ))}
        </ContentHolder>
        <ReadMore to={`/om-advokatene`}>Les mer om dem</ReadMore>
      </Card>
    </WrapperFiller>
  </Wrapper>
);
export default CardContentField;
